import React from 'react'
import { cn } from '~/utils/cn'
import * as styles from './Container.styles'

export type ContainerProps<T extends IntrinsicElement = 'div'> = {
  component?: T
} & React.ComponentPropsWithoutRef<'div'> &
  styles.Variants

const Container = <T extends IntrinsicElement = 'div'>({
  className,
  fluid = false,
  component = 'div' as T,
  ...restProps
}: ContainerProps<T>) => {
  return React.createElement(component, {
    className: cn(styles.containerVariants({ fluid }), className),
    ...restProps
  })
}

export default Container
