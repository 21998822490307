import { tv, type VariantProps } from 'tailwind-variants'

export const containerVariants = tv({
  base: '',
  variants: {
    fluid: {
      true: 'md:container-gutters-x w-full max-w-screen-2xl mx-auto',
      false: 'container-gutters-x container mx-auto'
    }
  },
  defaultVariants: {
    fluid: false
  }
})

export type Variants = VariantProps<typeof containerVariants>
